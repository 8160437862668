<template>
  <div class="salaryInfo-container">
    <list ref="list" :searchUrl="searchUrl" :exportUrl="exportUrl" exportPermission="export" :searchParams.sync="searchParams" :headers="headers" :isMultiSelect="true" :selectable="selectable">
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <!-- <v-select2 label="所属公司" v-model="searchParams.tenantId" v-bind="regionParams"></v-select2> -->
        <v-input v-model="searchParams.userName" label="姓名"></v-input>
        <div class="department">
          <span>部门</span>
        <select-tree
                :value.sync="searchParams.orgId"
                :data.sync="treeSelectData"
                placeholder="查询所属部门"
                :treeData="treeData"
                :treeProps="treeProps"
                :highlightCurrent="true"
                :filterNodeMethod="filterNodeMethod"
                :expand-on-click-node="false"
              /></div>
        <v-input v-model="searchParams.mobileNum" label="联系电话"></v-input>
        <v-input v-model="searchParams.cardNum" label="证件号码"></v-input>
        <!-- <v-datepicker type="date" v-model="searchParams.entryTime" label="薪资日期"/> -->
        <v-datepicker
          label="薪资日期"
          type="rangemonth"
          format="YYYY-MM-DD"
          :startTime.sync="searchParams.fmonthS"
          :endTime.sync="searchParams.fmonthE"
        ></v-datepicker>
        <v-select v-model="searchParams.salaryGrade" label="薪资档级" :options="salaryGradeOps"></v-select>
        <div class="tripMode">
          <span>当前状态</span
          ><checkbox-plus
            mode="checkbox-plus"
            type="default"
            :options="userTypeOps"
            :value.sync="userType"
            @onChange="userTypeChange"
          />
        </div>
        <v-select v-model="searchParams.status" label="是否发放" :options="statusOps"></v-select>
        <v-input v-model="searchParams.jobNum" label="员工编号"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" v-if="scope.row.status==0"></v-button>
        <v-button text="查看" type="text" permission="detail" @click="look(scope.row)"></v-button>
        <v-button text="发放" type="text" permission="grant" @click="grant(scope.row,1)" v-if="scope.row.status==0"></v-button>
        <v-button text="复制" type="text" permission="copy" @click="copy(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">       
        <v-button text="批量发放" permission="grant" @click="grant(scope.selectedData,2)"></v-button>
      </template>
    </list>
  </div>
</template>
<script>
import { getListURL, exportUrl, closeURL, getInitInfoURL,getEnterOrgTreeListURL } from './api'
import { statusOps,statusMap } from './map'
import { mapHelper } from 'common/utils'
import { CheckboxPlus,SelectTree } from 'components/bussiness'
import { regionParams } from 'common/select2Params'
export default {
  name: 'salaryStandardList',
  components: {CheckboxPlus,SelectTree},
  data () {
    return {
      orgType: 2,
      regionParams,
      searchUrl: getListURL,
      exportUrl: exportUrl,
      searchParams: {
        tenantId: '',
        userName: "",
        orgId: "",
        mobileNum: "",
        cardNum: "",
        jobNum: "",
        staffStatusList: undefined,      
        salaryGrade: undefined,
        status: undefined,  
        fmonthS: '',
        fmonthE: ''   
      },
      salaryGradeOps: [],
      statusOps:statusOps(1),
      userType: [],
      userTypeOps: [],
      headers: [
        {
          prop: 'tenantName',
          label: '公司名称'
        },
        {
          prop: 'fmonth',
          label: '薪资日期'
        },
        {
          prop: 'jobNum',
          label: '员工编号'
        },
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'cardType',
          label: '证件类型'
        },
        {
          prop: 'cardNum',
          label: '证件号码'
        },
        {
          prop: 'mobileNum',
          label: '联系电话'
        },
        {
          prop: 'orgName',
          label: '部门'
        },
        {
          prop: 'adminDuty',
          label: '岗位'
        },
        {
          prop: 'jobRank',
          label: '职级'
        },
        {
          prop: 'salaryGrade',
          label: '薪资档级'
        },       
        {
          prop: 'baseSalary',
          label: '基本工资'
        },
        {
          prop: 'performanceSalary',
          label: '绩效工资'
        },
        {
          prop: 'staffStatus',
          label: '当前状态'
        },
        {
          prop: 'status',
          label: '是否发放',
          formatter (row) {
            return statusMap[row.status]           
          }
        },
        {
          prop: 'updateUserName',
          label: '操作人'
        },
        
      ],
      /* select-tree 控件 */
      treeSelectData: {}, // 所选树节点的数据
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      treeData: [],
    }
  },
  created () {
    this.getInitInfo()
    this.getTreeList()
  },
  methods: {
    getTreeList () { // 所属部门树数据
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.orgType}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        })
    },
    /* 选择树控件 */
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    userTypeChange(index,val){
      // this.userType=val
      // this.searchParams.userType=val.toString()
      this.searchParams.staffStatusList = val.toString();
    },
    selectable (row) {
      return row.status !== 1
    },
    edit (row) {
      this.$router.push({
        name: 'salaryInfoForm',
        query: {
          id: row.id,
          edit: 1
        }
      })
    },
    copy (row) {
      this.$router.push({
        name: 'salaryInfoForm',
        query: {
          id: row.id
        }
      })
    },
    look (row) {
      this.$router.push({
        name: 'salaryInfoDetail',
        query: {
          id: row.id
        }
      })
    },
    create () {
      this.$router.push({
        name: 'salaryInfoForm'
      })
    },
    async grant(row,type) {
      console.log(row,'互殴')
      let data={}
      if(type==1){
        data.idList = row.id
      }else{
        data.idList =row.ids.join(',')
      }
      // let data = {
      //   idList: row.id
      // }
      let isConfirm = await this.$confirm('是否确认发放?')
      if (isConfirm) {
        this.$axios.post(closeURL,this.$qs.stringify(data)).then(res => {
          if (res.status === 100) {
            this.$refs.list.searchData()
            this.$message({
              type: 'success',
              message: '发放成功！',
              center: true
            })
          }
        })
      }
    },
    getInitInfo() {
      this.$axios.get(getInitInfoURL).then((res) => {
        if (res.status === 100) {
          // this.postOps = this.dealData(res.data.countryList); //部门
          this.salaryGradeOps = this.dealData(res.data.salaryGradeList);
          //this.statusOps = this.dealData(res.data.yesOrNoList)
          this.userTypeOps = this.dealData1(res.data.staffStatusList)
        }
      });
    },
    dealData(arr) {
      // 处理数据使符合下拉框格式
      let list = [];
      arr.forEach((item) => {
        list.push({
          text: item,
          value: item,
        });
      });
      const { setOps } = mapHelper.setMap(list);
      return list.length > 0 ? setOps(1) : list;
    },
    dealData1(arr) {
      // 处理数据使符合下拉框格式
      let list = [];
      arr.forEach((item) => {
        list.push({
          label: item,
          value: item,
        });
      });
      return list
    },
  }
}
</script>
<style lang="scss">
.salaryInfo-container {
  .tripMode {
    padding-left: 50px;
    display: flex;
    > span {
      display: inline-block;
      margin: 15px 10px 0 0;
    }
  }
  .department {
    padding-left: 50px;
    display: flex;
    > span {
      display: inline-block;
      margin: 10px 5px 0 0;
    }
  }
}
</style>
