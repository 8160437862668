/*
 * @Description:
 * @Author: 露露
 * @Date: 2019-08-21 08:47:38
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-12-06 14:59:53
 */
import { mapHelper } from 'common/utils'

// 政治面貌
const status = [
  {
    text: '已发放',
    value: 1
  },
  {
    text: '待发放',
    value: 0
  }
]
const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

export {
  statusMap,
  statusOps
}
