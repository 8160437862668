var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "salaryInfo-container" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          exportPermission: "export",
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          isMultiSelect: true,
          selectable: _vm.selectable,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "department" },
                  [
                    _c("span", [_vm._v("部门")]),
                    _c("select-tree", {
                      attrs: {
                        value: _vm.searchParams.orgId,
                        data: _vm.treeSelectData,
                        placeholder: "查询所属部门",
                        treeData: _vm.treeData,
                        treeProps: _vm.treeProps,
                        highlightCurrent: true,
                        filterNodeMethod: _vm.filterNodeMethod,
                        "expand-on-click-node": false,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.searchParams, "orgId", $event)
                        },
                        "update:data": function ($event) {
                          _vm.treeSelectData = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("v-input", {
                  attrs: { label: "联系电话" },
                  model: {
                    value: _vm.searchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "mobileNum", $$v)
                    },
                    expression: "searchParams.mobileNum",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "证件号码" },
                  model: {
                    value: _vm.searchParams.cardNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "cardNum", $$v)
                    },
                    expression: "searchParams.cardNum",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "薪资日期",
                    type: "rangemonth",
                    format: "YYYY-MM-DD",
                    startTime: _vm.searchParams.fmonthS,
                    endTime: _vm.searchParams.fmonthE,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "fmonthS", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "fmonthS", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "fmonthE", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "fmonthE", $event)
                    },
                  },
                }),
                _c("v-select", {
                  attrs: { label: "薪资档级", options: _vm.salaryGradeOps },
                  model: {
                    value: _vm.searchParams.salaryGrade,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "salaryGrade", $$v)
                    },
                    expression: "searchParams.salaryGrade",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "tripMode" },
                  [
                    _c("span", [_vm._v("当前状态")]),
                    _c("checkbox-plus", {
                      attrs: {
                        mode: "checkbox-plus",
                        type: "default",
                        options: _vm.userTypeOps,
                        value: _vm.userType,
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.userType = $event
                        },
                        onChange: _vm.userTypeChange,
                      },
                    }),
                  ],
                  1
                ),
                _c("v-select", {
                  attrs: { label: "是否发放", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "员工编号" },
                  model: {
                    value: _vm.searchParams.jobNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "jobNum", $$v)
                    },
                    expression: "searchParams.jobNum",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.status == 0
                  ? _c("v-button", {
                      attrs: {
                        text: "编辑",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _c("v-button", {
                  attrs: { text: "查看", type: "text", permission: "detail" },
                  on: {
                    click: function ($event) {
                      return _vm.look(scope.row)
                    },
                  },
                }),
                scope.row.status == 0
                  ? _c("v-button", {
                      attrs: {
                        text: "发放",
                        type: "text",
                        permission: "grant",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.grant(scope.row, 1)
                        },
                      },
                    })
                  : _vm._e(),
                _c("v-button", {
                  attrs: { text: "复制", type: "text", permission: "copy" },
                  on: {
                    click: function ($event) {
                      return _vm.copy(scope.row)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "批量发放", permission: "grant" },
                  on: {
                    click: function ($event) {
                      return _vm.grant(scope.selectedData, 2)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }